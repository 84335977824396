<template>
  <div class="text">
      <a class="link" href="#">Terms of service</a>
      <a class="link" href="#">Privacy Policy</a>
    </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
};
</script>

<style scoped>
.text{
  display: flex;
  justify-content: space-between;
  background: var(--main);
  padding: 23px 60px;
}
.link {
  text-decoration: none;
  color: var(--secondary);
}
</style>