<template>
<div class="container">
<div class="title" @click="display"><img src="./../assets/arwdwn.svg" class="arwdwn"> <span class="text">Change Language</span></div>
<ul v-if="ListShow" class="list">
  <li value="En" @click="display()"><router-link to="En"><img class="img" src="./../assets/En.png"> English</router-link></li>
  <li value="Pl" @click="display()"><router-link to="Pl"><img class="img" src="./../assets/Pl.png"> Polski</router-link></li>
  <li value="Ar" @click="display()"><router-link to="Ar"><img class="img" src="./../assets/Ar.png"> العربية</router-link></li>
</ul>
</div>
</template>

<script>
export default {
  name: "NavChangeLang",
  data() {
    return {
      ListShow: false,
    };
  },
  methods: {
    display() {
      this.ListShow = !this.ListShow;
    },
  },
};
</script>

<style scoped>
.container{
  margin-left: 32px;
}
.list{
  list-style: none;
  position: absolute;
  background-color: var(--secondary);
  top: 42px;
  color: var(--text);
  border-right: black 2px solid;
  border-left: black 2px solid;
  border-bottom: black 2px solid;
  border-radius: 8px;
}
img.img{
  max-width: 28px;
}
li:hover {
  background: var(--text);
}
li{
  padding: 12px;
  border-radius: 8px;
}
li a{
  text-decoration: none;
}
.title {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 38px;
  color: var(--text);
}
.arwdwn {
  max-width: 18px;
  position: absolute;
  left: 6px;
  filter: var(--icon);
}

@media (max-width: 425px) {
  .text{
    display: none;
  }
}
</style>