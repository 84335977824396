<template>
  <div class="En">
  <Nav />
  <WText />
  <Features />
  <Servers />
  <Footer />
  </div>
</template>

<script>
import WText from "./../components/WText.vue";
import Features from "./../components/Features.vue";
import Nav from "./../components/NavBar.vue";
import Footer from "./../components/Footer.vue";
import Servers from "./../components/Servers.vue";

export default {
  name: "En",
  components: {
    WText,
    Features,
    Nav,
    Footer,
    Servers,
  },
};
</script>

<style scoped>

</style>
