<template>
  <nav class="navbar">
            <span class="item item3"><ChangeLang :Ar="LangShow" /></span>
            <router-link to="/" class="brand-title"><img src="./../assets/logo1.png" class="gbot" /><span class="name">GOLD BOT</span></router-link>
            <a href="#" class="toggle-button" @click="navToggle">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
            </a>
            <div class="navbar-links" :class="{ 'active': Navs }" id="NavLink">
            <ul>
                <li class="item"><router-link to="/commands"><img src="./../assets/terminal-solid.svg" class="terminal" />Commands</router-link></li>
                <li class="item"><a class="support" href="https://discord.gg/Vhd97v2DrN" target="_blank"><img src="./../assets/circle-question-regular.svg" class="support" />Support</a></li>
            </ul>
            </div>
           
      </nav>
      
</template>

<script>
import ChangeLang from "./ChangeLang.vue";

export default {
  name: "Nav",
  components: {
    ChangeLang,
  },
  data() {
    return {
      Navs: false,
    };
  },
  methods: {
    navToggle() {
      this.Navs = !this.Navs;
    },
  },
};
</script>

<style scoped>
.item3{
    position: relative;
    top: 19px;
    margin-left: 17px;
}
.support {
    position: relative;
    bottom: 3px;
}
.brand-title {
    margin: 0 40px;
}
img.gbot {
    max-width: 40px;
    cursor: pointer;
}

img.terminal {
    max-width: 20px;
    margin: 0 10px;
    filter: invert(47%) sepia(70%) saturate(430%) hue-rotate(142deg) brightness(91%) contrast(81%);
}

img.support {
    position: relative;
    top: 3px;
    left: 2px;
    max-width: 20px;
    margin: 0 10px;
    display: inline-block;
    filter: invert(47%) sepia(70%) saturate(430%) hue-rotate(142deg) brightness(91%) contrast(81%);
}

    .toggle-button{
        top: -2px;
    }

li.item {
    white-space: nowrap;
}

.navbar {
    transition: all 0.5s;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary);
    color: white;
    padding: 10px;
}

.name{
    font-size: 1.8rem;
    text-decoration: none;
    margin: .9rem;
    font-weight: 600;
    position: absolute;
    top: 9px;
    color: var(--main);
}

.navbar-links {
    height: 100%;
}

.navbar-links ul {
    display: flex;
    padding: 0;
    margin: 0 40px;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li a {
    display: block;
    color: var(--text);
    text-decoration: none;
    padding: 1rem;
}

.toggle-button {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    margin: 20px;
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    background-color: var(--text);
    border-radius: 10px;
}

@media (max-width: 800px) {
    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .toggle-button {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links ul li {
        text-align: center;
    }

    .navbar-links ul li a {
        padding: .5rem 1rem;
    }

    .navbar-links.active {
        display: flex;
    }
    .gbot {
        position: absolute;
        display: none;
    }
    .brand-title span.name{
        position: absolute;
        height: 34px;
        right: 18rem;
        text-decoration: none;
    }
}
@media (max-width: 425px) {
    .navbar{
        padding: 0;
    }
    .brand-title span.name{
        top: 1px;
        right: 140px;
        font-size: 26px;
    }
    .navbar {
        padding-bottom: 13px;
    }
        .toggle-button{
        top: 0px;
    }
}

@media (max-width: 325px) {
    .brand-title span.name{
        top: 1px;
        right: 96px;
        font-size: 24px;
    }

}
</style>