<template>
  <div class="wrapper">
    <img class="logo" src="./../assets/logo1.png">
    <span class="brand">GOLD BOT</span>
    <div class="text">Create a professional discord server.</div>
    <div class="btns"><a href="https://invite.goldbot.pl/" class="btn"><img src="./../assets/link.svg" class="link">Invite bot</a>
    <a href="https://discord.gg/Vhd97v2DrN" class="btn"><img class="sup" src="./../assets/circle-question-regular.svg">Support Server</a><br>
    <a href="https://top.gg/bot/914149735810617364/vote" class="btn vote"><img src="./../assets/arrow.svg" class="arw">Vote</a></div>
    <Switch />


  </div>
</template>

<script>
import Switch from "./Switch.vue";
export default {
  name: "WText",
  components: {
    Switch,
  },
};
</script>

<style scoped>

.wrapper{
    background: var(--main);
    width: 100%;
    display: grid;
    place-items: center;
    padding-bottom: 240px;
}
.logo {
    max-width: 150px;
    margin-top: 90px;
}
.brand {
    font-size: 50px;
    font-weight: 600;
    font-family: 'Open Sans', arial, sans-serif;;
}
.btn {
  margin: 10px;
  background: var(--secondary);
  color: var(--text);
  border-radius: 10px;
  padding: 12px;
  text-decoration: none;
}
.btns{
margin-top: 20px;
padding-bottom: 60px;
white-space: nowrap;
text-align: center;
}

.sup {
  max-width: 20px;
  margin: 2px;
  top: 5px;
  position: relative;
}

.link {
  max-width: 15px;
  margin: 4px;
  position: relative;
  top: 6px;
}
.arw {
  max-width: 10px;
  position: relative;
  top: 4px;
  margin: 4px;
}

.vote {
position: relative;
top: 30px;
}

@media (max-width: 768px) {
.logo {
  max-width: 120px;
}
}
@media (max-width: 425px) {
.logo{
  display: none;
}
.brand{
  margin-top: 160px;
}
}
@media (max-width: 375px) {
.brand{
  font-size: 36px;
}
.btn{
  padding: 10px;
} 
.text{
  font-size: 16px;
}
}


</style>