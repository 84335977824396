<template>
  <div class="container">
  <h1 class="c">Features</h1>
    <div class="content">
            <Feature v-for="(feature, index) in features" :key="index" :title="feature.title" :img="feature.src">
                {{feature.desc}}
            </Feature>
    </div>
</div>

</template>

<script>
import Feature from "./../components/Feature.vue";
export default {
  name: "Features",
  components: { Feature },
  data() {
    return {
      features: [
        {
          title: "Efficient",
          desc: `We are focused with the bot's performance in order for it to function properly.`,
          src: "https://i8.ae/PKFJr",
        },
        {
          title: "Secure",
          desc: `The bot token is securely stored to ensure the bot's security.`,
          src: "https://i8.ae/rZgvp",
        },
        {
          title: "Trust",
          desc: "Over 79 servers trust our bot.",
          src: "https://i8.ae/kAJZP",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
    margin: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.c{
text-align: center;
padding-top: 70px;
color: var(--text);
}
.container {
    background: var(--secondary);
    padding-bottom: 40px;
    transition: all 0.5s;
}

@media (max-width:1023px){

    .content{
        flex-direction: column;
        align-items: center;
    }
}

</style>