<template>

            <div class="card">
                <div class="icon">
                    <img :src="img" class="icon">
                </div>
                <div class="info">
                    <h3>{{title}}</h3>
                    <p><slot></slot></p>
                </div>
            </div>
</template>

<script>
export default {
  name: "Feature",
  props: {
    title: String,
    img: String,
  },
};
</script>

<style scoped>
.card {
    background-color: var(--secondary);
    width: 21.25em;
    box-shadow: 0 5px 25px rgba(1 1 1 / 15%);
    border-radius: 10px;
    padding: 25px;
    margin: 15px;
    transition: 0.7s ease;
}

.card:hover {
    transform: scale(1.1);
}


.card .icon {
    max-width: 70px;
    text-align: center;
    position: relative;
    margin: 12px;
    left: 50px;
    filter: var(--icon);
}

.info {
    text-align: center;
}

.info h3{
    color: var(--text);
    font-size: 1.2em;
    font-weight: 700;
    margin: 10px;
}
@media (max-width: 1024px) {
    .card .icon {
        position: relative;
        left: 34px;
    }
}


@media (max-width: 768px) {
    .card .icon {
        left: 50px;
    }
}
@media (max-width: 376px) {
    .card {
        max-width: 260px;
    }
    .card .icon{
        left: 30px;
    }
    .card:hover {
    transform: scale(1);
}
}
</style>