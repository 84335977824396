<template>
<router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
:root {
  --main: #AD91FF;
  --secondary: #E1F4F9;
  --text: #348FA1;
  --icon: invert(50%) sepia(58%) saturate(498%) hue-rotate(142deg) brightness(87%) contrast(82%);
}

*, *::before, *::after {
box-sizing: border-box;
margin: 0%;
padding: 0;
font-family: poppins, sans-serif;
transition: all 0.4;
}



</style>
