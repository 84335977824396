<template>
<label class="switch1">
  <input type="checkbox" v-model="Switch">
  <span class="slider1"></span>
</label>
</template>

<script>
import { ref, watch } from "@vue/runtime-core";

export default {
  name: "Switch",
  setup() {
    const root = document.querySelector(":root");
    let Switch = ref(false);

    watch(Switch, () => {
      if (Switch.value === true) {
        root.style.setProperty("--main", "#8685EF");
        root.style.setProperty("--secondary", "#454545");
        root.style.setProperty("--text", "#8F9191");
        root.style.setProperty(
          "--icon",
          "invert(97%) sepia(95%) saturate(872%) hue-rotate(181deg) brightness(107%) contrast(103%)"
        );
      } else if (Switch.value === false) {
        root.style.setProperty("--main", "#AD91FF");
        root.style.setProperty("--secondary", "#E1F4F9");
        root.style.setProperty("--text", "#348FA1");
        root.style.setProperty(
          "--icon",
          "invert(50%) sepia(58%) saturate(498%) hue-rotate(142deg) brightness(87%) contrast(82%)"
        );
      }
    });
    return { Switch };
  },
};
</script>

<style scoped>
.switch1 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 16px;
}
.switch1 input{
  opacity: 0;
  width: 0;
  height: 0;
}
.slider1{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #41C0F2;
  box-shadow: inset 2px 3px 2px rgba(0, 0, 0, 0.2), inset -2px -3px 2px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 34px;
  transition: 0.4s;
}
.slider1::before{
  content:'🌞';
  position: absolute;
  left: 6px;
  bottom: 3px;
  height: 26px;
  width: 26px;
  transition: 0.4s;
}
input:checked + .slider1{
  background-color: #212940;
}
input:checked + .slider1::before{
  transform: translatex(25px);
  content:'🌙';
}
</style>