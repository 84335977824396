import {createRouter, createWebHistory} from 'vue-router'
import En from '../views/En.vue'
import cmd from '../views/Cmd.vue'

const routes = [,
{
  path: '/En',
  name: 'En',
  component: En
},
{
  path: '/',
  redirect: '/En',
},
{
  path: '/commands',
  name: 'cmd',
  component: cmd,
} ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router