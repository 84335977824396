<template>
  <div class="container">

    <div class="text">
      <h1 class="main">Join our community</h1>
      <div class="cards">
      <div class="count">+{{serverCount}}</div>
      <div class="title">Servers</div>
      <div class="count">+{{Math.round(userCount / 1000)}}k</div>
      <div class="title">Users</div>
        <div class="sub">
          <a href="https://discord.gg/Vhd97v2DrN" target="_blank"><img src="./../assets/discord.svg" class="subicon"></a>
          <a href="https://top.gg/bot/914149735810617364/vote" target="_blank"><img src="./../assets/topgg.png" class="subicon"></a>
        </div>
      </div>
    </div>
    <div class="links">
      <a href="https://tiktok.com/@gold_bot.official" target="_blank"><img src="./../assets/tk.svg" class="icon"> </a>
      <a href="https://youtube.com/channel/UCcdECPAgkzsbb2ThsjX88MA" target="_blank"><img src="./../assets/yt.svg" class="icon"></a>
      <a href="https://discord.gg/Vhd97v2DrN" target="_blank"><img src="./../assets/discord.svg" class="icon"></a>
      <a href="https://top.gg/bot/914149735810617364/vote" target="_blank"><img src="./../assets/topgg.png" class="icon"></a>
    </div>

  </div>
</template>

<script>
export default {
  name: "Servers",
  data() {
    return {
      serverCount: 115,
      userCount: 103001,
    };
  },
};
</script>

<style scoped>
.sub{
  display: none;
}
.main {
    font-size: 50px;
}
.container{
    display: flex;
    padding: 200px 120px; 
    justify-content: space-between;
    background: var(--text);
}
.cards{
    margin-top: 40px;
}
.count{
    margin: 12px;
    font-size: 40px;
}
.title{
    position: relative;
    left: 14px;
}
.links{
  text-align: left;
}
.icon{
  min-width: 14px;
  max-width: 29px;
  margin: 18px;
  display: inline;
}

@media (max-width: 425px) {
  .icon{
    display: none;
  }
.container{
  justify-content: center;
}
}
@media (max-width: 375px) {
  .main{
    font-size: 35px;
  }
  .subicon{
    min-width: 12px;
    max-width: 44px;
    margin: 5px;
  }
  .sub{
    display: block;
    margin-top: 20px;
  }
}
</style>